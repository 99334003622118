@import url("https://fonts.googleapis.com/css2?family=Allison&family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary: #948065;
  --text-primary: #333632;
  --bg-one: #f9f9f9;
  --bg-two: #111111;
  --bg-body: #f8f8e0;
  --transition: 400ms all ease-in-out;
}
.primary-link {
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  border: 2px solid white;
  font-family: "Jost", sans-serif;
  padding: 0.5rem 4rem;
  transition: var(--transition);
}
.primary-link:hover {
  background-color: rgba(255, 255, 255, 0.5);
  transition: var(--transition);
}
.basic-link {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  border: 2px solid white;
  font-family: "Jost", sans-serif;
  padding: 0.2rem 2rem;
  transition: var(--transition);
}
.basic-link:hover {
  background-color: rgba(255, 255, 255, 0.5);
  transition: var(--transition);
}
.arrow-to-top {
  position: fixed;
  bottom: 15vh;
  right: 2rem;
  background: #df9739;
  z-index: 200;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 0.5rem;
  cursor: pointer;
}
.whatsapp-to-top {
  position: fixed;
  bottom: 7vh;
  right: 2rem;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.5rem;
  color: green;
  z-index: 200;
  border-radius: 0.5rem;
  cursor: pointer;
  border: 1px solid #df9739;
}
/* ::-webkit-scrollbar {
  width: 0.5rem;
}
::-webkit-scrollbar-track {
  border: 0.5rem solid var(--bg-one);
  box-shadow: inset 0 0 0.1rem 0.1rem var(--bg-one);
}
::-webkit-scrollbar-thumb {
  background: var(--bg-one);
  border-radius: 1rem;
  border: 1px solid var(--bg-logo);
} */

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 900px) {
  .primary-link {
    text-decoration: none;
    color: white;
    font-size: 1rem;
    border: 2px solid white;
    font-family: "Jost", sans-serif;
    padding: 0.5rem 3rem;
    transition: var(--transition);
  }
  .primary-link:hover {
    background-color: rgba(255, 255, 255, 0.5);
    transition: var(--transition);
  }
}
@media screen and (max-width: 600px) {
  .primary-link {
    text-decoration: none;
    color: white;
    font-size: 1rem;
    border: 2px solid white;
    font-family: "Jost", sans-serif;
    padding: 0.5rem 3rem;
    transition: var(--transition);
  }
  .primary-link:hover {
    background-color: rgba(255, 255, 255, 0.5);
    transition: var(--transition);
  }

  .arrow-to-top {
    position: fixed;
    bottom: 13vh;
    right: 0.8rem;
    background: #df9739;
    z-index: 200;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  .whatsapp-to-top {
    position: fixed;
    bottom: 7vh;
    right: 0.8rem;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: green;
    z-index: 200;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 1px solid #df9739;
  }
}
@media screen and (max-width: 420px) {
  .primary-link {
    text-decoration: none;
    color: white;
    font-size: 1rem;
    border: 2px solid white;
    font-family: "Jost", sans-serif;
    padding: 0.5rem 3rem;
    transition: var(--transition);
  }
  .primary-link:hover {
    background-color: rgba(255, 255, 255, 0.5);
    transition: var(--transition);
  }
}
@media screen and (max-width: 350px) {
  .primary-link {
    text-decoration: none;
    color: white;
    font-size: 1rem;
    border: 2px solid white;
    font-family: "Jost", sans-serif;
    padding: 0.5rem 3rem;
    transition: var(--transition);
  }
  .primary-link:hover {
    background-color: rgba(255, 255, 255, 0.5);
    transition: var(--transition);
  }
}
